import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeAdminGuard } from './guards/authorize-admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'horario-disponivel-listar',
    pathMatch: 'full'
  },
  {
    path: 'admin/usuario-listar',
    loadChildren: () => import('./pages/admin/usuario-listar/usuario-listar.module').then(m => m.UsuarioListarPageModule)
  },
  {
    path: 'admin/usuario-adicionar',
    loadChildren: () => import('./pages/admin/usuario-adicionar/usuario-adicionar.module').then(m => m.UsuarioAdicionarPageModule)
  },
  {
    path: 'admin/login',
    loadChildren: () => import('./pages/admin/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'admin/home',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'admin/lembrar-senha',
    loadChildren: () => import('./pages/admin/lembrar-senha/lembrar-senha.module').then(m => m.LembrarSenhaPageModule)
  },
  {
    path: 'admin/local-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/local-listar/local-listar.module').then(m => m.LocalListarPageModule)
  },
  {
    path: 'admin/local-adicionar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/local-adicionar/local-adicionar.module').then(m => m.LocalAdicionarPageModule)
  },
  {
    path: 'admin/tipo-atendimento-adicionar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/tipo-atendimento-adicionar/tipo-atendimento-adicionar.module').then(m => m.TipoAtendimentoAdicionarPageModule)
  },
  {
    path: 'admin/tipo-atendimento-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/tipo-atendimento-listar/tipo-atendimento-listar.module').then(m => m.TipoAtendimentoListarPageModule)
  },
  {
    path: 'admin/tipo-atendimento-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/tipo-atendimento-alterar/tipo-atendimento-alterar.module').then(m => m.TipoAtendimentoAlterarPageModule)
  },
  {
    path: 'admin/local-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/local-alterar/local-alterar.module').then(m => m.LocalAlterarPageModule)
  },
  {
    path: 'admin/guiche-adicionar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/guiche-adicionar/guiche-adicionar.module').then(m => m.GuicheAdicionarPageModule)
  },
  {
    path: 'admin/guiche-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/guiche-alterar/guiche-alterar.module').then(m => m.GuicheAlterarPageModule)
  },
  {
    path: 'admin/guiche-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/guiche-listar/guiche-listar.module').then(m => m.GuicheListarPageModule)
  },
  {
    path: 'admin/grade-padrao-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/grade-padrao-listar/grade-padrao-listar.module').then(m => m.GradePadraoListarPageModule)
  },
  {
    path: 'admin/grade-padrao-adicionar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/grade-padrao-adicionar/grade-padrao-adicionar.module').then(m => m.GradePadraoAdicionarPageModule)
  },
  {
    path: 'admin/grade-padrao-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/grade-padrao-alterar/grade-padrao-alterar.module').then(m => m.GradePadraoAlterarPageModule)
  },
  {
    path: 'admin/gradepadrao-guiche-tipoatendimento-listar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-guiche-tipoatendimento-listar/gradepadrao-guiche-tipoatendimento-listar.module').then(m => m.GradepadraoGuicheTipoatendimentoListarPageModule)
  },
  {
    path: 'admin/gradepadrao-guiche-tipoatendimento-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-guiche-tipoatendimento-alterar/gradepadrao-guiche-tipoatendimento-alterar.module').then(m => m.GradepadraoGuicheTipoatendimentoAlterarPageModule)
  },
  {
    path: 'admin/gradepadrao-guiche-tipoatendimento-adicionar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-guiche-tipoatendimento-adicionar/gradepadrao-guiche-tipoatendimento-adicionar.module').then(m => m.GradepadraoGuicheTipoatendimentoAdicionarPageModule)
  },
  {
    path: 'admin/gradepadrao-horario-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-horario-alterar/gradepadrao-horario-alterar.module').then(m => m.GradepadraoHorarioAlterarPageModule)
  },
  {
    path: 'admin/gradepadrao-horario-listar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-horario-listar/gradepadrao-horario-listar.module').then(m => m.GradepadraoHorarioListarPageModule)
  },
  {
    path: 'admin/gradepadrao-horario-adicionar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-horario-adicionar/gradepadrao-horario-adicionar.module').then(m => m.GradepadraoHorarioAdicionarPageModule)
  },
  {
    path: 'admin/grade-diaria-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/grade-diaria-listar/grade-diaria-listar.module').then(m => m.GradeDiariaListarPageModule)
  },
  {
    path: 'admin/grade-diaria-adicionar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/grade-diaria-adicionar/grade-diaria-adicionar.module').then(m => m.GradeDiariaAdicionarPageModule)
  },
  {
    path: 'horario-disponivel-listar',
    loadChildren: () => import('./pages/horario-disponivel-listar/horario-disponivel-listar.module').then(m => m.HorarioDisponivelListarPageModule)
  },
  {
    path: 'cliente-adicionar',
    loadChildren: () => import('./pages/cliente-adicionar/cliente-adicionar.module').then(m => m.ClienteAdicionarPageModule)
  },
  {
    path: 'agenda-listar',
    loadChildren: () => import('./pages/agenda-listar/agenda-listar.module').then(m => m.AgendaListarPageModule)
  },
  {
    path: 'admin/atendimento-listar',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/Admin/atendimento-listar/atendimento-listar.module').then(m => m.AtendimentoListarPageModule)
  },
  {
    path: 'modal-atendimento',
    loadChildren: () => import('./pages/Admin/modal-atendimento/modal-atendimento.module').then(m => m.ModalAtendimentoPageModule)
  },
  {
    path: 'admin/relatorio',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/relatorio/relatorio.module').then(m => m.RelatorioPageModule)
  },
  {
    path: 'admin/rel-atendimento',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/rel-atendimento/rel-atendimento.module').then(m => m.RelAtendimentoPageModule)
  },
  {
    path: 'admin/gradepadrao-intervalo-listar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/gradepadrao-intervalo-listar/gradepadrao-intervalo-listar.module').then(m => m.GradepadraoIntervaloListarPageModule)
  },
  {
    path: 'admin/gradepadrao-intervalo-adicionar/:id',
    loadChildren: () => import('./pages/admin/gradepadrao-intervalo-adicionar/gradepadrao-intervalo-adicionar.module').then(m => m.GradepadraoIntervaloAdicionarPageModule)
  },
  {
    path: 'versao',
    loadChildren: () => import('./pages/admin/versao/versao.module').then(m => m.VersaoPageModule)
  },
  {
    path: 'admin/usuario-alterar/:id',
    canActivate: [AuthorizeAdminGuard],
    loadChildren: () => import('./pages/admin/usuario-alterar/usuario-alterar.module').then(m => m.UsuarioAlterarPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
