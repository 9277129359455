import { Component, OnInit } from '@angular/core';

import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilService } from './services/util.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  paginaAdminCollection = [];
  paginaAbertaCollection: any[] = [];
  usuarioLogado = false;
  private promptInstallEvent;
  private toast: HTMLIonToastElement;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private utilService: UtilService,
    private swUpdate: SwUpdate,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.utilService.getEvent().subscribe((resp: any) => {
      //debugger;
      //console.log(resp);

      if (resp.key == "usuarioLogado") {
        if (resp.value == true) {
          this.usuarioLogado = true;
          this.paginaAbertaCollection = [];
          this.populaMenuAdmin();
        }
        else {
          this.paginaAdminCollection = [];
          this.populaMenuPaginaAberta();
        }
      }
    });

    if (this.utilService.usuarioLogado() == true) {
      this.usuarioLogado = true;
      this.paginaAbertaCollection = [];
      this.populaMenuAdmin();
    }
    else {
      this.paginaAdminCollection = [];
      this.populaMenuPaginaAberta();
    }
  }

  async ngOnInit() {

    console.log(`Runing app ${this.isPWAInstalled ? 'standalone' : 'in browser'}`);

    this.swUpdate.available.subscribe(async event => {

      console.log('current version is', event.current);
      console.log('available version is', event.available);

      if (event.current !== event.available) {
        const alert = await this.alertCtrl.create({
          header: 'Oba, Temos Novidades!',
          subHeader: 'Há uma nova versão disponível da aplicação.',
          message: 'Deseja atualizar agora?',
          buttons: [
            {
              text: 'Instalar',
              handler: () => { this.swUpdate.activateUpdate(); }
            },
            'Mais tarde'
          ]
        });
        alert.present();
      }
    });

    this.swUpdate.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    await this.platform.ready();

    if (!this.isMobile) {
      this.checkForUpdate();
      if (!this.isPWAInstalled) {
        this.listenForInstallEvent();
      }
    }

    console.log('swUpdate.isEnabled: ' + this.swUpdate.isEnabled);
    //this.swUpdate.checkForUpdate();
  }
  private listenForInstallEvent() {
    window.addEventListener('beforeinstallprompt', async (e) => {
      e.preventDefault();
      this.promptInstallEvent = e;

      setTimeout(() => {
        this.suggestInstall();
      }, 5000);
    });
  }
  private async suggestInstall() {
    this.toast = await this.toastCtrl.create({
      message: 'Você pode usar este aplicativo offline',
      buttons: [{
        text: 'Baixar',
        handler: () => { this.installPWA(); },
      }, {
        text: '',
        icon: 'close'
      }],
      duration: 0,
    });
    this.toast.present();
  }

  private installPWA() {
    this.toast.dismiss();
    // Show the prompt
    this.promptInstallEvent.prompt();
    // Wait for the user to respond to the prompt
    this.promptInstallEvent.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.promptInstallEvent = null;
      });
  }

  get isMobile() {
    return this.platform.is('mobile');
  }
  get isPWAInstalled(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  }

  async checkForUpdate() {
    console.log('Check for updates');
    try {
      await this.swUpdate.checkForUpdate();
    } catch (e) {
      console.debug('service worker not available');
    }
  }

  sair() {
    localStorage.clear();
    this.navCtrl.navigateRoot('admin/login');
    this.utilService.publishEvent("usuarioLogado", false);
  }

  populaMenuAdmin() {
    let perfil = localStorage.getItem(btoa("perfil"));

    if (perfil == btoa("1")) {
      this.paginaAdminCollection = [
        {
          title: 'Atendimentos',
          url: 'admin/atendimento-listar',
          icon: 'alarm'
        },
        {
          title: 'Guichês',
          url: 'admin/guiche-listar',
          icon: 'storefront'
        },
        {
          title: 'Locais de Atendimento',
          url: 'admin/local-listar',
          icon: 'business'
        },
        {
          title: 'Tipos de Atendimento',
          url: 'admin/tipo-atendimento-listar',
          icon: 'trail-sign'
        },
        {
          title: 'Grade Padrão',
          url: 'admin/grade-padrao-listar',
          icon: 'time'
        },
        {
          title: 'Grade Diária',
          url: 'admin/grade-diaria-listar',
          icon: 'sunny'
        },
        {
          title: 'Usuários',
          url: 'admin/usuario-listar',
          icon: 'people'
        },
        {
          title: 'Relatórios',
          url: 'admin/relatorio',
          icon: 'reader'
        },
      ];
    }
    else if (perfil == btoa("2")) {
      this.paginaAdminCollection = [
        {
          title: 'Atendimentos',
          url: 'admin/atendimento-listar',
          icon: 'alarm'
        },
      ];
    }
  }
  populaMenuPaginaAberta() {
    this.paginaAbertaCollection = [
      {
        title: 'Agendar',
        url: 'horario-disponivel-listar',
        icon: 'alarm'
      },
      {
        title: 'Consultar',
        url: 'agenda-listar',
        icon: 'search'
      },
    ];
  }

  exibirVersao() {
    if (this.usuarioLogado == true) {
      this.navCtrl.navigateForward('versao');
    }
  }
}
